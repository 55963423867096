import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'



const AdminBoard = () => {
  return (
    <div>AdminBoard</div>
  )
}

export default AdminBoard